import moment from "moment";
import ErrorMsg from "../components/templates/ErrorMsg";
import ReactGA from "react-ga4";
import { sidebarLinks } from "../constants/sidebarLinks";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
}

export const downloadURI = (file) => {
  let link = document.createElement("a");
  link.href = file.link;
  link.download = file.name;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const filterByName = (fn, arr, name, isArrOfObjects = false) => {
  if (!isArrOfObjects)
    return fn(() => arr?.filter((file) => file !== name));
  else
    return fn(() => arr?.filter((file) => file.fileName !== name));
}

export const returnErrorMessage = (files, singleFileMessage, multipleFilesMessage, areFiles = true) => {
  if (files?.length)
    if (areFiles) return <ErrorMsg>{files?.length === 1 ? `${singleFileMessage} \n• ${files[0]?.name} ` : `${multipleFilesMessage} \n• ${files?.map(file => file?.name).join(',\r\n• ')}`}</ErrorMsg>
    else return <ErrorMsg>{files?.length === 1 ? `${singleFileMessage} \n• ${files[0]} ` : `${multipleFilesMessage} \n• ${files?.map(file => file).join(',\r\n• ')}`}</ErrorMsg>
}

const returnFileNameAndRowsWithErrors = file => `${file?.fileName} on ${pluralizeWord(file?.rows?.length, 'row', 'rows')}: ${file?.rows?.join(', ')}`

export const returnErrorMessageWithRows = (files, singleFileMessage, multipleFilesMessage) => {
  if (files?.length)
    if (files?.length === 1)
      return <ErrorMsg>{`${singleFileMessage} \n• ${files[0]?.fileName} on ${pluralizeWord(files[0]?.rows?.length, 'row', 'rows')}: ${files[0]?.rows?.join(', ')}`}</ErrorMsg>
    else return <ErrorMsg>{`${multipleFilesMessage} \n• ${files?.map(file => returnFileNameAndRowsWithErrors(file) ).join(',\r\n• ')}`}</ErrorMsg>
}

function setEntryAttribute(attribute) {
  return attribute ? attribute : 'N/A';
}

function setEntryWeight(weight) {
  const res = setEntryAttribute(weight);
  return res !== 'N/A' ? `${res}g` : 'N/A';
}

function setEntryCarbonFootprint(carbonFootprint) {
  const res = setEntryAttribute(carbonFootprint);
  return res !== 'N/A' ? `${res.toFixed(2)}kg CO₂` : 'N/A';
}

export const setAttributes = {
  setEntryAttribute,
  setEntryWeight,
  setEntryCarbonFootprint
}

export const returnStringIfItExists = (str) => {
  return str || '';
}

export const withModal = (condition, fn, ...params) => {
  !condition && fn(...params);
  if (condition) {
    const result = window.confirm("There are changes left to submit. Do you want to continue?");
    if (result) fn(...params);
  }
}

export const returnPercentagePosition = (sandboxProportions) => {
  if (String(sandboxProportions.percentage).length <= 1) {
    return "left-[23px]";
  } else if (String(sandboxProportions.percentage).length <= 2) {
    return "left-[33px]";
  } else {
    return "left-[43px]";
  }
};

export const returnIsValidNumberInput = (newValue, currentValue = null) => {
  const regex = new RegExp(/^-?\d*([,.]\d{0,2})?$/);

  const convertedValue = String(newValue).replace(",", ".");
  newValue = newValue.replace(",", ".");
  
  if(newValue === "-") return false;
  if (!regex.test(newValue)) return false;
  if (convertedValue.length === 2 && newValue === "00") return false;
  if (newValue < 0 ) return false;

  return true;
}

export const returnIsValidPercentageInput = (newValue) => {
  if (newValue.includes('+')) return false;
  if (newValue === "") return true;
  if (newValue.match(/[^\d+\b]/)) return false;
  if (newValue < 0 || Number(newValue) === 0 || newValue > 100) return false;
  if (newValue.length === 2 && newValue === "00") return false;

  return true;
}

export const returnHoursAndMinutes = (time) => {
  const date = new Date();
  const formattedDate = moment(date).format("DD.MM.YYYY");
  const hour = date.getHours();
  const minutes = date.getMinutes();

  return `${formattedDate} - ${hour}:${minutes}`;
}

export const addDecimalsToNumber = (num) => {
  if (num % 1 === 0) {
    return num.toFixed(2);
  } else {
    return num.toFixed(3);
  }
}

export const checkIfArrayOfObjectsIncludesValue = (arrOfObj, key, valueToCompareWith) => arrOfObj?.some((obj) => obj[key] === valueToCompareWith);

export const pluralizeWord = (length, singular, plural) => length > 1 ? plural : singular;

export const analyticsClickedButton = (pageName, buttonName) => {
  ReactGA.event({
    category: 'button',
    action: 'Button click',
    label: `${pageName} : ${buttonName}`,
  });
};

export const analyticsInputChange = (category, action, label) => {
  //EXAMPLE
    // category: 'Emission search',
    // action: 'Search emissions',
    // label: marginImpactDetails.query

  ReactGA.event({
    category,
    action,
    label
  });
}

export const getSidebarLinks = (userRole) => {

  if (userRole === 'consultancy') {
    // sorting since they want dashboards and then sandbox
    return Object.values(sidebarLinks).filter(link => link.roles.includes(userRole)).sort((a, b) => a.name.localeCompare(b.name));
  }

  return Object.values(sidebarLinks).filter(link => link.roles.includes(userRole));
}
