/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import TickBoxSvg from "../../../../assets/svgs/Sandbox/TickBoxSvg";
import { useInView } from "react-intersection-observer";
import CalendarInput from "./CalendarInput";
import { SandboxContext } from "../../../context/SandboxContext";
import { useNavigate } from "react-router-dom";
import Spinner from "../../templates/Spinner";
import SandboxToolTip from "../../templates/SandboxToolTip";
import fetchTop100emissions from "../../../utils/fetchTop100Emissions";
import fetchEmissions from "../../../utils/fetchEmissions";
import Top100Emissions from "./Components/Top100Emissions";
import { analyticsClickedButton, analyticsInputChange } from "../../../utils/utils";
import { UserContext } from "../../../context/UserContext";

export default function SelectExistingProduct() {
  const {companyId} = useContext(UserContext);
  const {
    setSelectedProducts,
    editedProducts,
    setEditedProducts,
    marginImpactDetails,
    setMarginImpactDetails,
    selectedProducts,
    searchedProducts,
    setSearchedProducts,
    sandbox,
    allProducts,
    setAllProducts
  } = useContext(SandboxContext);
  const [rangeFilter, setRangeFilter] = useState(marginImpactDetails.dataRange);
  const [rangeError, setRangeError] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [showSearchError, setShowSearchError] = useState(false);
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
        fetchData(rangeFilter);
      }, 500);

    return () => clearTimeout(timer);
  }, [marginImpactDetails.query, rangeFilter]);

  useEffect(() => {
    if (inView) {
      setSearchedProducts((prev) => [...prev, ...allProducts.slice(prev.length, prev.length + 10)]);
    }
  }, [inView]);

  const fetchData = async ( rangeFilter=null ) => {
    analyticsInputChange('Emission search', 'Search emissions', marginImpactDetails.query);
    setShowSearchError(false);

    let details = {
      text: marginImpactDetails.query,
      fromDate: rangeFilter !== null ? rangeFilter[0] : 0,
      toDate: rangeFilter !== null ? rangeFilter[1] : 0,
      companyId: companyId
    };

    if (allProducts.length === 0) {
      setSendingRequest(true);
    }

    const result = details.text.length > 0 ? await fetchEmissions(details) : await fetchTop100emissions(details);

    if (allProducts.length === 0) {
      setSendingRequest(false);
    }

    if (result?.data?.length > 0) {
      setMarginImpactDetails({
        ...marginImpactDetails,
        dataRange: rangeFilter,
        revenue: result.revenue, 
        totalCo2: result.totalCo2,
      })
      
      const data = result?.data?.map((item) => {
        item.price = 0;
        item.selected = sandbox.id ? selectedProducts.find((product) => product.product_id === item.product_id) ? true : false : false;
        item.percentage = "100";
        item.selected = editedProducts.find((product) => product.product_id === item.product_id) ? true : false;

        return item;
      });

      setAllProducts(data);
      setSearchedProducts([...data.slice(0, 10)]);
    } else if (searchedProducts.length === 0) {
      setShowSearchError(true);
    }
  };

  const selectProduct = (index) => {
    const newProducts = [...searchedProducts];
    newProducts[index].selected = !newProducts[index].selected;
    setSearchedProducts(newProducts);
  };

  const handleSelectProductButton = () => {
    if (searchedProducts.length > 0) {
      const filteredProducts = searchedProducts.filter((product) => product.selected);

      if (filteredProducts.length > 0) {
        const newEditedProducts = [...editedProducts];
        const newSelectedProducts = [...selectedProducts];

        filteredProducts.forEach((product) => {
          const foundProduct = newEditedProducts.find((item) => item.product_id === product.product_id);

          if (!foundProduct) {
            newEditedProducts.push(product);
            newSelectedProducts.push(product);
          }
        });

        setSelectedProducts(newEditedProducts);
        setEditedProducts(newSelectedProducts);

        navigate("/sandbox/search-products");
      }
    }
  };

  const handleSearchProductInput = (value) => {
    setSearchedProducts([]);
    setAllProducts([]);
    setMarginImpactDetails({
      ...marginImpactDetails,
      query: value
    })

    if (rangeFilter === null) {
      setRangeError(true);
    } else {
      setRangeError(false);
    }
  } 

  const handleRangeFilterChange = (value) => {
    setRangeError(false);
    setSearchedProducts([]);
    setAllProducts([]);
    setRangeFilter(value);
  }

  const findSelectedProducts = () => {
    const selectedProducts = searchedProducts.filter((product) => product.selected);

    return selectedProducts.length > 0;
  };

  return (
    <>
      <div className="mx-[64px] mt-[24px]">
        <div className="text-[#585858] w-[550px] text-base secondary-font mb-[16px]">
          Search for an existing product here to review what impact changing
          this item might have. Please select the data range you would like to
          review.
        </div>
        <div className="flex flex-row flex-wrap justify-between">
          <div className="flex flex-row flex-wrap">
            <div>
              <CalendarInput
                setRangeFilter={handleRangeFilterChange}
                rangeError={rangeError}
                rangeFilter={rangeFilter}
              />
            </div>
            <input
              className="w-[544px] h-[56px] border rounded-[8px] pl-[16px] text-[#585858] text-base shadow-md secondary-font ml-[50px] focus:outline-none"
              placeholder="Search for existing product"
              value={marginImpactDetails.query}
              onChange={(e) => handleSearchProductInput(e.target.value)}
            />
          </div>
        </div>
        {rangeError && (
          <div className="ml-4 mt-2 text-red-500">
            Please select a date range first
          </div>
        )}
        <div className={`${ rangeError ? "pt-[22px]" : "pt-[48px]" } text-2xl main-font-bolded`}>
          {searchedProducts.length > 0 && marginImpactDetails.query.length > 0 ? "Existing products" : "Top 100 Highest Co2"}
        </div>
        {sendingRequest && <Spinner />}
        {!sendingRequest && searchedProducts.length > 0 && marginImpactDetails.query.length > 0 ? (
          <div className={`${findSelectedProducts() > 0 ? 'max-h-[calc(100vh-485px)]' : 'max-h-[70vh]'} list-scrollbar`}>
            <div className="grid grid-cols-9 mt-[24px] text-base text-left secondary-font text-[15px]">
              <div className="pl-[24px] col-span-2">Product Name</div>
              <div>Supplier</div>
              <div>SKU</div>
              <div>Case Size</div>
              <div>CO2 per KG</div>
              <div>CO2 KG in Period</div>
              <div className="col-span-2">Quantity KG</div>
            </div>
            <div className={`secondary-font`}>
              {searchedProducts.map((product, index) => (
                <div
                  ref={index === searchedProducts.length - 1 ? ref : null}
                  key={product.product_id}>
                  <div className="min-h-[80px] grid grid-cols-9 items-center mb-[24px] text-[15px] bg-white text-Grey text-left border rounded-xl shadow-sm leading-[20px] box-shadow">
                    <div className="col-span-2">
                      <SandboxToolTip text={product.product} details={'left-[120px] top-[-30px] w-[300px]'}>
                        <div className="pl-[24px] leading-5 text-base truncate">
                          {product.product}
                        </div>
                      </SandboxToolTip>
                    </div>
                    <div>{product.supplier}</div>
                    <div>{product.sku}</div>
                    <div>{product.caseSize}</div>
                    <div>{parseFloat(product.co2perkg).toFixed(2)}</div>
                    <div>{parseFloat(product.totalCo2).toFixed(2)}</div>
                    <div>{parseFloat(product.weight / 1000).toFixed(2)}</div>
                    <div
                      className="flex flex-row justify-end cursor-pointer mr-[30px]"
                      onClick={() => selectProduct(index)}>
                      <TickBoxSvg
                        selected={
                          searchedProducts[index].selected ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Top100Emissions fetchData={() => fetchData(rangeFilter)}/>
        )}
        {showSearchError && (
          <div className="flex justify-center mt-[100px]">
            <div className="text-[34px] main-font-bolded text-center max-w-[600px] leading-10">
              Sorry, we couldn't find any products that match your request.
            </div>
          </div>
        )}
      </div>
      {findSelectedProducts() > 0 && (
          <div className="absolute bottom-0 w-full bg-[#FBFBF8]">
            <div className="left-0 w-full h-0 border border-DarkStone"></div>
            <div>
              <div className="flex flex-row justify-center my-[36px]">
                <div
                  className="green-button mr-[16px] cursor-pointer"
                  onClick={() => {
                    handleSelectProductButton();
                    analyticsClickedButton('Select Existing Product', 'Select Products');
                    }}>
                  Select Products
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}
