import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { classNames } from '../../../utils/utils';
import fetchDashboard from '../../../utils/fetchDashboard';
import dashboardSpinner from '../../../../assets/animations/dashboardSpinner.json';
import { UserContext } from '../../../context/UserContext';
import BackDrop from '../../templates/BackDrop';
import CurlyArrowSvg from '../../../../assets/svgs/UploadCSV/CurlyArrowSvg';
import CompanyDropdown from './CompanyDropdown';
import fetchConsultancyDash from '../../../utils/fetchConsultancyDash';

export default function UnfilteredDashboard({ slug }) {
  const { companyId, role } = useContext(UserContext);
  const iFrame = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isHidden, setIsHidden] = useState(true);
  const [isBackDropVisible, setIsBackDropVisible] = useState(true);

  const removeBackDrop = useCallback(() => {
    setIsBackDropVisible(false);
    setTimeout(() => {
      setIsHidden(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (companyId !== -1 || role !== 'consultancy') {
      removeBackDrop();
    }
  }, [companyId, removeBackDrop, role]);

  useEffect(() => {
    if (!isHidden) {
      !isLoading && setIsLoading(true);
      if (role === 'consultancy') {
        fetchConsultancyDash(companyId, slug).then(({ iframeUrl }) => {
          iFrame.current.src = iframeUrl;
          iFrame.current.onload = () => setIsLoading(false);
        });
      } else {
        fetchDashboard(slug).then(({ iframeUrl }) => {
          iFrame.current.src = iframeUrl;
          iFrame.current.onload = () => setIsLoading(false);
        });
      }
    }
  }, [slug, isHidden, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="absolute top-40 right-[4.25rem]">
        {role === 'consultancy' && (
          <CompanyDropdown onSelect={removeBackDrop} />
        )}
      </div>
      {isLoading && (
        <Lottie
          animationData={dashboardSpinner}
          loop={true}
          className="h-under-header bg-Stone/30"
        />
      )}
      <div
        className={classNames(
          isLoading ? '' : 'hidden',
          'w-full h-under-header bg-Stone/30'
        )}
      />
      {isBackDropVisible && companyId === -1 && role === 'consultancy' && (
        <BackDrop>
          <div className="absolute right-[18.5rem] top-60 rotate-90">
            <CurlyArrowSvg />
          </div>
        </BackDrop>
      )}
      <iframe
        title="metabase-dashboard"
        ref={iFrame}
        className="w-full h-under-header bg-White  pl-[45px] pr-[47px]"
      />
    </>
  );
}
