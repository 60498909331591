export async function sendCode(id, verificationCode) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/local/complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, code: verificationCode })
    });
    return res.json();
  } catch (err) {
    console.error(err);
  }
}

export async function resendCode(id) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/local/resend-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id })
    });
    return res.json();
  } catch (err) {
    console.error(err);
  }
}