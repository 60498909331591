import { useContext, useState, useCallback } from 'react';
import { UserContext } from '../../../context/UserContext';
import RequestCompanyModal from '../SandBox/Modals/RequestCompanyModal';

export default function RequestNewCompanyCTA() {
  const { role } = useContext(UserContext);

  const [isModalShown, setIsModalShown] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalShown(false);
  }, []);

  const openModal = useCallback(() => {
    setIsModalShown(true);
  }, []);

  if (role === 'consultancy') {
    return (
      <>
        <button type='button' onClick={openModal} className="green-button">Request new company</button>
        {isModalShown && <RequestCompanyModal isModalShown={isModalShown} closeModal={closeModal} />}
      </>
    );
  }

  return null;
}
