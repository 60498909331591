export default async function fetchGetSavedSandboxes(id, companyId) {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sandbox-scenarios/search/${id}${companyId ? '&'+companyId : ''}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });

        return res.json();
    } catch (error) {
        console.log(error);
    }
}