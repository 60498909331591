import React, { useContext } from "react";
import SandboxBoilerPlate from "../templates/SandboxBoilerPlate";
import SelectedProducts from "../parts/SandBox/SelectedProducts";
import { SandboxContext } from "../../context/SandboxContext";

export default function SandboxSelectedProducts() {
  const {showCompareProductsScreen} = useContext(SandboxContext);

  return (
    <SandboxBoilerPlate title={`${showCompareProductsScreen ? 'Compare Products' : 'Search for a product'}`} showCreateButton={false} className="h-full">
        <SelectedProducts />
    </SandboxBoilerPlate>
  );
}
