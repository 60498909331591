import React, { useContext } from "react";
import { Field } from "formik";
import InfoSvg from "../../../../../assets/svgs/Sandbox/InfoSvg";
import { returnIsValidNumberInput } from "../../../../utils/utils";
import { SandboxContext } from "../../../../context/SandboxContext";
import InfoIconToolTip from "../../../templates/InfoIconToolTip";

export default function FormInput({
  name,
  label,
  value,
  onBlur,
  onChange,
  showInfoIcon = false,
  inputType = "text",
  readyToContactSupport = false,
  handleUncompletedDataModal,
  showError = false,
  resetForm
}) {
  const { setShowRequestHelpModal, setShowCreateModal } =
    useContext(SandboxContext);

  const validateInput = (event) => {
    if (inputType !== "text") {
      const value = event.target.value.replace(",", ".");

      const checkedValue = returnIsValidNumberInput(value);

      if (!checkedValue) return;

      event.target.value = value;

      onChange(event);
    } else {
      onChange(event);
    }
  };

  return (
    <div className="flex justify-center relative">
      <div className="relative xl:w-96 w-[360px]" data-te-input-wrapper-init>
        <Field
          type="text"
          className={`${! showError ? 'border-Grey' : 'border-red-500'} min-h-[56px] pt-[15px] w-full rounded-lg bg-transparent leading-[1.6] focus:border-Grey focus:ring-0 focus:outline-none`}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={validateInput}
        />
        <label
          htmlFor={name}
          className={`${ value.length !== 0 ? "-translate-y-[0.925rem] scale-[0.95] text-[13px]" : 'text-[16px]' } ${showError ? "text-red-500" : "text-[#585858]"}
          pointer-events-none font-normal secondary-font absolute top-2.5 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-5 transition-all duration-200 ease-out peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}>
          {label}
        </label>
        {showInfoIcon && (
          <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-[16px]">
            <InfoIconToolTip
              text="In order to contact Zedible support, you'll need to fill in the mandatory fields"
              details={'right-[-140px] top-[-90px] w-[200px]'}>
              <span
                className="text-gray-500 secondary-font text-[15px]"
                onClick={() => {
                  if (readyToContactSupport) {
                    setShowCreateModal(false);
                    setShowRequestHelpModal(true);
                    resetForm();
                  } else {
                    handleUncompletedDataModal();
                  }
                }}>
                <InfoSvg />
              </span>
            </InfoIconToolTip>
          </div>
        )}
      </div>
    </div>
  );
}
