export default async function fetchSavedSandboxes(companyId) {
      try {
          const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sandbox-scenarios/${companyId ? companyId : ''}`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }
            });
  
          return res.json();
      } catch (error) {
          console.log(error);
      }
  }