import { useState, useContext, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import useIsLoggedIn from '../../../hooks/useAuth';
import { resendCode, sendCode } from '../../../utils/authCalls';
import { UserContext } from '../../../context/UserContext';

const MFASchema = Yup.object().shape({
  verificationCode: Yup.string()
    .matches(/\d+/, 'The code must contain digits')
    .min(4, 'The code must have 4 digits')
    .max(4, 'The code must have 4 digits')
    .required('A valid verification code is required'),
});

export default function MFAForm() {
  const { id, last2Digits, setIsLoading } = useContext(UserContext);
  const [wrongCode, setWrongCode] = useState(false);
  const [resendCodeMessage, setResendCodeMessage] = useState({
    message: '',
    isError: null,
  });
  const buttonRef = useRef(null);

  const { setToken } = useIsLoggedIn();

  function sendCodeReq(values) {
    const { verificationCode } = values;
    sendCode(id.toString(), verificationCode)
      .then((data) => {
        if (!data?.jwt) {
          setWrongCode(true);
        } else {
          setIsLoading(true);
          setToken(data.jwt);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="flex justify-center items-center mt-[48px] ">
      <Formik
        initialValues={{
          verificationCode: '',
        }}
        validationSchema={MFASchema}
        onSubmit={sendCodeReq}
      >
        {({ errors, touched }) => (
          <Form className="mt-8 space-y-6 w-[35%]">
            <div className="text-center">
              Please input the verification code we sent via SMS to the phone
              number ending in **{last2Digits}.<br /> It may take a few seconds
              for the SMS to arrive.
            </div>
            <div className="rounded-md -space-y-px flex flex-col gap-5">
              <div>
                <Field
                  id="verificationCode"
                  name="verificationCode"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Verification code"
                />
                {errors.verificationCode && touched.verificationCode ? (
                  <p className="flex justify-center text-Error">
                    {errors.verificationCode}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-5">
                {wrongCode && (
                  <div className="text-Error text-center">
                    The verification code is invalid
                  </div>
                )}
                <button
                  ref={buttonRef}
                  type="button"
                  onClick={() => {
                    resendCode(id.toString())
                      .then((data) => {
                        buttonRef.current.disabled = true;
                        setResendCodeMessage({
                          message: 'Code resent!',
                          isError: false,
                        });
                      })
                      .catch((_err) => {
                        buttonRef.current.disabled = true;
                        setResendCodeMessage({
                          message: 'Something went wrong',
                          isError: true,
                        });
                      })
                      .finally(() => {
                        setTimeout(() => {
                          buttonRef.current.disabled = false;
                          setResendCodeMessage({ message: '', isError: null });
                        }, 2000);
                      });
                  }}
                >
                  Resend Code
                </button>
                {resendCodeMessage.message && (
                  <div
                    className={`${
                      resendCodeMessage.isError ? 'text-Error' : ''
                    } text-center`}
                  >
                    {resendCodeMessage.message}
                  </div>
                )}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="group relative w-1/2 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-Green"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
