/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, } from 'react';
import SandboxToolTip from '../../../templates/SandboxToolTip';
import { useInView } from 'react-intersection-observer';
import InfoSvg from '../../../../../assets/svgs/Sandbox/InfoSvg';
import Spinner from '../../../templates/Spinner';
import ProductRow from './ProductRow';

export default function ShowProducts({
  stateButtons,
  pageDetails,
  selectProduct,
  setPageDetails,
  sendingRequest,
}) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setPageDetails((prev) => (
        {
          ...prev,
          products: [...prev.products, ...prev.allProducts.slice(prev.products.length, prev.products.length + 10)]
        }
      ))
    }
  }, [inView]);

  return (
    <div className="pl-[64px] pr-[54px]">
      <div className="grid grid-cols-10 mt-[24px] text-base text-left secondary-font text-[15px] mb-1">
        <div className="pl-[24px] col-span-2">Product Name</div>
        {(stateButtons.product || stateButtons.savedProduct) && (
          <div className="col-span-2">SKU</div>
        )}
        {(stateButtons.product || stateButtons.savedProduct) && (
          <div>Supplier</div>
        )}
        <div>Category</div>
        <div className={`${!stateButtons.product && 'col-span-4'}`}>
          CO2 per KG
        </div>
        {stateButtons.product && (
          <div className="col-span-1 flex">
            <div className="mr-[9px]">Verified</div>
            <SandboxToolTip
              text="Whenever possible, select verified products which come directly from the manufacturer. Please note that if no verified product is available, a similar product from a supplier will be used, which may result in some variation in CO2 values."
              details={
                'left-[10px] top-[-30px] w-[100px] lg:left-[120px] lg:w-[500px]'
              }
            >
              <div className="mb-[8px] cursor-pointer">
                <InfoSvg />
              </div>
            </SandboxToolTip>
          </div>
        )}
        {stateButtons.product && <div>Recipe</div>}
      </div>
      <div className="secondary-font">
        {pageDetails.products.map((product, index) => {
          return <ProductRow product={product} key={product?.product_id} index={index} stateButtons={stateButtons} pageDetails={pageDetails} selectProduct={selectProduct} rowRef={ref} />
        })}
      </div>
      {sendingRequest && (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
}
