import { useState, createContext, useEffect, useCallback } from 'react';
import fetchValidation from '../utils/fetchValidation';

export const UserContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {/*this is intentional*/},
  isLoading: true,
  setIsLoading: () => {/*this is intentional*/},
  role: null,
  setRole: () => {/*this is intentional*/},
  last2Digits: '',
  setLast2Digits: () => {/*this is intentional*/},
  id: -1,
  setId: () => {/*this is intentional*/},
  isVerifyingCode: false,
  setIsVerifyingCode: () => {/*this is intentional*/},
  companyId: -1,
  saveCompany: () => {/*this is intentional*/}
});

export default function UserProvider({ children }) {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [id, setId] = useState(null);
  const [last2Digits, setLast2Digits] = useState('');
  const [companyId, setCompanyId] = useState(-1);

  useEffect(() => {
    authorize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authorize = useCallback(async () => {
		const token = localStorage.getItem('token');
		if (!token) {
			setIsLoggedIn(false);
			return setIsLoading(false);
		}

    setId(null);
    setCompanyId(-1);
    setRole(null);

    fetchValidation().then(res => res.json())
    .then((res) => {
          if (res) {
            setIsLoggedIn(true);
            if (res?.role) {
              setRole(res.role.toLowerCase());
              if (res.role.toLowerCase() === 'authenticated' && res?.companyId) {
                saveCompany(res.companyId);
              }
            }
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('company-id')
            setRole(null);
            setIsLoggedIn(false);
            setCompanyId(-1);
          }
        })
        .catch((_err) => {
          localStorage.removeItem('token');
          localStorage.removeItem('company-id')
          setRole(null);
          setIsLoggedIn(false);
          setCompanyId(-1);
        })
        .finally(() => {
            setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); 

  const restoreCompany = useCallback(() => {
    if (localStorage.getItem('company-id')) {
      const id = parseInt(localStorage.getItem('company-id'))
      setCompanyId(id ?? -1);
    }
  }, []);

  useEffect(() => {
    restoreCompany();
  }, [isLoggedIn, restoreCompany]);

  const saveCompany = useCallback((id) => {
    setCompanyId(id);
    localStorage.setItem('company-id', id);
  }, []);

  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, isLoading, setIsLoading, authorize, isVerifyingCode, setIsVerifyingCode, id, setId, role, setRole, last2Digits, setLast2Digits, companyId, saveCompany }}>
      {children}
    </UserContext.Provider>
  );
}
