import { useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';

export default function useAuth() {
  const { setIsLoggedIn, authorize, setIsVerifyingCode, setId, setLast2Digits, isVerifyingCode, setRole } = useContext(UserContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setToken(token) {
    localStorage.setItem('token', token);
    authorize();
    if (isVerifyingCode) {
      endSession();
    }
  }

  function startSession(id, last2Digits) {
    setId(id);
    setIsVerifyingCode(true);
    setLast2Digits(last2Digits);
  }

  function endSession() {
    setId(-1);
    setIsVerifyingCode(false);
    setLast2Digits('');
  }

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('company-id');
    setRole(null);
    setIsLoggedIn(false);
  }
  
  return {
    setToken,
    logout,
    startSession,
    endSession
  }
}
