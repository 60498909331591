/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SandboxContext } from "../../../context/SandboxContext";
import {
  analyticsClickedButton,
  returnHoursAndMinutes,
  returnIsValidNumberInput,
  returnIsValidPercentageInput,
  returnPercentagePosition,
} from "../../../utils/utils";
import SandboxToolTip from "../../templates/SandboxToolTip";
import MissingDataModal from "./Modals/MissingDataModal";

export default function SearchProducts({showButtons}) {
  const { selectedProducts, setSelectedProducts, editedProducts, setEditedProducts, sandbox, setSandbox, searchedProducts, setSearchedProducts } = useContext(SandboxContext);
  const [sandboxDetails, setSandboxDetails] = useState({
    name: `All - ${returnHoursAndMinutes()}`,
    co2perkg: "",
    totalCo2: "",
    weight: "",
    price: "",
    percentage: 0,
  });

  const [sandboxProportions, setSandboxProportions] = useState({
    percentage: sandbox.toSandboxPercentage || "50",
    totalCo2: "",
    quantity: "",
  });

  const [showMissingDataModal, setShowMissingDataModal] = useState(false);

  const navigate = useNavigate();

  const handleProductPrice = (id, newPrice) => {
    const newSelectedProducts = editedProducts.map((product) => {
      if (product.product_id === id) {
        const checkedInput = returnIsValidNumberInput(newPrice, product.price);

        if (!checkedInput) return product;
        
        return {
          ...product,
          price: newPrice.length === 1 && newPrice === "." ? "0." : newPrice.replace(",", "."),
        };
      }
      return product;
    });

    handleSandboxDetailsChange(newSelectedProducts);
    setEditedProducts(newSelectedProducts);
  };

  const handleProductPercentage = (id, newPercentage) => {
    const checkedInput = returnIsValidPercentageInput(newPercentage);

    if (!checkedInput) return;

    const newSelectedProducts = editedProducts.map((product, index) => {
      if (product.product_id === id) {
        const newData = {
          ...product,
          percentage: newPercentage,
          weight: (
            (newPercentage / 100) *
            selectedProducts[index].weight
          ).toFixed(2),
          totalCo2: (
            (newPercentage / 100) *
            selectedProducts[index].totalCo2
          ).toFixed(2),
        };

        return newData;
      }
      return product;
    });

    setEditedProducts(newSelectedProducts);
    handleSandboxDetailsChange(newSelectedProducts);
  };

  const handleSandboxPercentageChange = (value) => {
    const checkedInput = returnIsValidPercentageInput(value);

    if (!checkedInput) return;

    setSandboxProportions({
      percentage: value,
      quantity: ((value / 100) * sandboxDetails.weight).toFixed(2),
      totalCo2: ((value / 100) * sandboxDetails.totalCo2).toFixed(2),
    });
  };

  const handleSandboxQuantityChange = (value) => {
    const checkedInput = returnIsValidNumberInput(value, sandboxDetails.weight);

    if (!checkedInput || sandboxDetails.weight < value) return;

    value = value.replace(",", ".");
    const newPercentageValue = (value / sandboxDetails.weight) * 100;

    setSandboxProportions({
      percentage: value !== "." ? Math.round(newPercentageValue) : 0,
      quantity: value.replace(",", "."),
      totalCo2: (
        (value / sandboxDetails.weight) *
        sandboxDetails.totalCo2
      ).toFixed(2),
    });
  };

  const handleSandboxDetailsChange = (products) => {
    const totalCo2 = products.reduce((acc, product) => {
      return acc + Number(product.totalCo2);
    }, 0);

    const totalWeight = products.reduce((acc, product) => {
        return acc + Number(product.weight);
      }, 0) / 1000;

    const totalPrice = products.reduce((acc, product) => {
      return acc + Number(product.price * product.weight / 1000);
    }, 0);

    const totalPercentage = products.reduce((acc, product) => {
      return acc + Number(product.weight * product.percentage);
    }, 0);

    setSandboxDetails({
      ...sandboxDetails,
      co2perkg: totalWeight !==0 ? (totalCo2 / totalWeight).toFixed(2) : 0,
      totalCo2: totalCo2.toFixed(2),
      weight: totalWeight,
      price: totalWeight !== 0 && totalPrice !== 0 ? (totalPrice / totalWeight).toFixed(2) : 0,
      percentage: ((totalPercentage / totalWeight) / 1000).toFixed(2),
    });

    setSandboxProportions({
      ...sandboxProportions,
      quantity: ((sandboxProportions.percentage / 100) * totalWeight).toFixed(
        2
      ),
      totalCo2: ((sandboxProportions.percentage / 100) * totalCo2).toFixed(2),
    });
  };

  const handleClearSelectedProducts = (id) => {
    setSelectedProducts(selectedProducts.filter((product) => product.product_id !== id));
    setEditedProducts(editedProducts.filter((product) => product.product_id !== id));

    const modifiedSearchedProducts = searchedProducts.map((product) => {
      if (product.product_id === id) {
        return {
          ...product,
          selected: false,
        };
      }
      return product;
    });

    setSearchedProducts(modifiedSearchedProducts);
  }

  const handleAddToSandboxButton = () => {
    const verifiedInputs = verifyInputs();

    if (verifiedInputs) {
      const mappedEditedProducts = editedProducts.map((product) => {
        return {
          ...product,
          price: product.price === "" ? 0 : product.price,
        }
      })

      setEditedProducts(mappedEditedProducts);

      setSandbox({
        id: null,
        name: sandboxDetails.name,
        co2perkg: sandboxDetails.co2perkg,
        totalCo2: sandboxProportions.totalCo2,
        weight: sandboxDetails.weight,
        price: sandboxDetails.price,
        percentage: 100,
        toSandboxPercentage: sandboxProportions.percentage,
      });
      navigate("/sandbox/selected-products");
    } else {
      setShowMissingDataModal(true);
    }
  }

  const verifyInputs = () => {
    let error = true;

    editedProducts.forEach((product) => {
      if (product.percentage === "") {
        error = false;
      }
    });

    // eslint-disable-next-line eqeqeq
    if (sandboxProportions.percentage === "" || sandboxProportions.percentage == 0) {
      error = false;
    }

    if (sandboxDetails.name === "") {
      error = false;
    }

    return error;
  }

  useEffect(() => {
    if (editedProducts.length > 0) {
      handleSandboxDetailsChange(editedProducts);
    } else {
      navigate("/sandbox/select-product");
    }
  }, [editedProducts]);

  return (
    <div>
      <>
        <div className={`${!showButtons ? 'mt-[100px]' : ''} mx-[64px]`}>
          <div className="main-font-bolded mt-[32px] mb-[24px] text-[17px] pt-[50px] font-bold leading-5">
            Your existing product
          </div>
          <div className="grid grid-cols-12 gap-2 grid-auto-flow mt-[24px] secondary-font text-[15px] w-full items-end pr-[50px] leading-5">
            <div className="pl-[24px] col-span-3">Product Name</div>
            <div className="col-span-2">Supplier</div>
            <div>SKU</div>
            <div>Case Size</div>
            <div>CO2 per KG</div>
            <div>CO2 KG in Period</div>
            <div>Quantity KG</div>
            <div className="flex justify-center">
              <div>%</div>
            </div>
            <div>Price Per KG</div>
          </div>
          <div className="mt-[10px]">
            {editedProducts.map((product) => (
              <div key={product.product_id} className="flex justify-start items-center mb-[16px] leading-5">
                  <div
                  className="w-full secondary-font text-Grey grid grid-cols-12 gap-2 grid-auto-flow items-center text-base bg-white border rounded-xl min-h-[80px] box-shadow">
                  <div className="col-span-3">
                    <SandboxToolTip text={product.product} details={'left-[120px] top-[-30px] w-[300px]'}>
                      <div className="pl-[24px] leading-5 truncate">
                        {product.product}
                      </div>
                    </SandboxToolTip>
                  </div>
                  <div className="text-[15px] col-span-2">{product.supplier}</div>
                  <div className="text-[15px]">{product.sku}</div>
                  <div className="text-[15px]">{product.caseSize}</div>
                  <div className="text-[15px]">{parseFloat(product.co2perkg).toFixed(2)}</div>
                  <div className="text-[15px]">{parseFloat(product.totalCo2).toFixed(2)}</div>
                  <div className="text-[15px]">{parseFloat(product.weight / 1000).toFixed(2)}</div>
                  <div className="flex flex-row justify-center">
                    <div className="max-w-[56px]">
                      <input
                        className={`${!product.percentage ? 'border-red-500 focus:border-red-500' : 'focus:border-Lightgrey'} w-full border rounded-[8px] py-[10px] px-[12px] pl-[8px] text-[15px] focus:ring-0 focus:outline-none`}
                        value={product.percentage}
                        onChange={(e) =>
                          handleProductPercentage(
                            product.product_id,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="relative max-w-[104px] mr-[37px] min-w-[50px]">
                    <input
                      className="w-full border rounded-[8px] py-[10px] px-[12px] pl-[20px] text-[15px] focus:border-Lightgrey focus:ring-0 focus:outline-none"
                      value={product.price}
                      onChange={(e) =>
                        handleProductPrice(product.product_id, e.target.value)
                      }
                    />
                    <span className="absolute left-[10px] top-[10px]">£</span>
                  </div>
                </div>
                <div className="bg-Lightgrey rounded-full py-1 px-3 ml-4 secondary-font cursor-pointer" onClick={() => handleClearSelectedProducts(product.product_id)}>X</div>
              </div>
            ))}
          </div>
        </div>
        <div className="mx-[64px] pr-[50px]">
          <div className="box-shadow w-full secondary-font text-[15px] text-Grey grid grid-cols-12 gap-2 grid-auto-flow items-center text-base border border-black rounded-xl leading-[20px] min-h-[80px]">
            <div className="col-span-7 ml-[12px]">
              <div
                className="relative xl:w-96 w-[360px]"
                data-te-input-wrapper-init>
                <input
                  type="text"
                  className={`${!sandboxDetails.name ? 'border-red-500 focus:border-red-500' : 'border-black'} max-w-[252px] main-font text-black pt-[25px] peer block min-h-[56px] w-full rounded-lg bg-transparent px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 focus:border-black focus:ring-0 focus:outline-none`}
                  id="summaryName"
                  name="summaryName"
                  value={sandboxDetails.name}
                  onChange={(e) =>{
                    if (e.target.value.length <= 32) {
                      setSandboxDetails({
                        ...sandboxDetails,
                        name: e.target.value,
                      })
                    }
                  }}
                />
                <label
                  htmlFor="summaryName"
                  className={`${
                    sandboxDetails.name.length !== 0
                      ? "-translate-y-[0.9rem] scale-[0.8] top-4"
                      : "top-4"
                  } pointer-events-none secondary-font text-base absolute left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-[#585858] transition-all duration-200 ease-out peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}>
                  Sandbox Name
                </label>
              </div>
            </div>
            <div>{sandboxDetails.co2perkg}</div>
            <div>{sandboxDetails.totalCo2}</div>
            <div className="col-span-2">{parseFloat(sandboxDetails.weight).toFixed(2)}</div>
            <div className="relative">
              <div className="px-[12px] pl-[20px]">{sandboxDetails.price}</div>
              <span className="absolute left-[10px] bottom-[0.5px]">£</span>
            </div>
          </div>
        </div>
        <div className="w-full h-0 border border-DarkStone pt-[56px]"></div>
        <div className="mx-[64px] mb-[250px] pr-[50px]">
          <div className="mt-[76px] grid grid-cols-8 text-[15px] secondary-font mb-[10px]">
            <div className="col-span-6"></div>
            <div>%</div>
            <div>Quantity KG</div>
          </div>
          <div className="grid grid-cols-8 items-center">
            <div className="col-span-2">
              <div className="col-span-4 text-left text-[17px] main-font-bolded max-w-[260px] text-black">
                Select Proportion of purchasing to use in Sandbox
              </div>
            </div>
            <div className="col-span-6 bg-white min-h-[80px] box-shadow grid grid-cols-6 items-center">
              <div className="col-span-4 main-font-bolded ml-[24px]">
                To Sandbox
              </div>
              <div className="relative">
                <input
                  className={`${!sandboxProportions.percentage ? 'border-red-500 focus:border-red-500' : 'focus:border-Lightgrey'} ${!showButtons ? 'max-w-[60px]' : 'max-w-[104px]'} border rounded-[8px] py-[10px] px-[12px] text-[15px] secondary-font text-Grey  focus:ring-0 focus:outline-none`}
                  value={sandboxProportions.percentage}
                  onChange={(e) =>handleSandboxPercentageChange(e.target.value)}
                />
                {String(sandboxProportions.percentage).length > 0 && (
                  <span
                    className={`${returnPercentagePosition(sandboxProportions)} absolute bottom-0 top-[11px] text-[15px] secondary-font text-Grey`}>
                    %
                  </span>
                )}
              </div>
              <div>
                <input
                  className={`${!showButtons ? 'max-w-[70px]' : 'max-w-[104px]'} border rounded-[8px] py-[10px] px-[12px] text-[15px] secondary-font text-Grey focus:border-Lightgrey focus:ring-0 focus:outline-none`}
                  value={sandboxProportions.quantity}
                  onChange={(e) => handleSandboxQuantityChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {showButtons && (
          <div className="absolute bottom-0 w-full bg-[#FBFBF8]">
          <div div className="w-full h-0 border border-DarkStone"></div>
          <div className="mx-[36px] mb-[36px]">
            <div className="flex justify-center mt-[36px]">
              <div
                className="green-button mr-2 cursor-pointer"
                onClick={() => {
                  analyticsClickedButton('Search for a product', 'Adjust Selection');
                  navigate("/sandbox/select-product");
                  }}>
                Adjust Selection
              </div>
              <div
                className={`${!verifyInputs() ? 'opacity-60 cursor-default' : '' } yellow-button`}
                onClick={() => {
                  analyticsClickedButton('Search for a product', 'Add to Sandbox');
                  handleAddToSandboxButton();
                  }}>
                Add to Sandbox
              </div>
            </div>
          </div>
        </div>
        )}
        {showMissingDataModal && (
          <MissingDataModal showMissingDataModal={showMissingDataModal} setShowMissingDataModal={setShowMissingDataModal} />
        )}
      </>
    </div>
  );
}
