export default async function fetchSandboxSavedProducts(details) {
  //repair
    try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sandbox-products/search/${details.text}${details.companyId ? '&'+details.companyId : ''}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });

        return res.json();
    } catch (error) {
        console.log(error);
    }
}