import {baseUrl} from "./baseUrl";

const administrationLinks = {
  'upload-data': {
    name: 'Upload Data', href: `${baseUrl.administration}/upload-data`,
  },
}
const dashboardLinks = {
  'carbon-overview': {
    name: 'Carbon Overview', href: `${baseUrl.dashboards}/carbon-overview`, slug: 'carbon-overview', isFiltered: false
  },
  'product-category': {
    name: 'Product Category', href: `${baseUrl.dashboards}/product-category`, slug: 'product-category', isFiltered: false
  },
  'drill-by-category': {
    name: 'Drill by Category', href: `${baseUrl.dashboards}/drill-by-category`, slug: 'drill-by-category', isFiltered: true, text: 'Please select a category to view data'
  },
  'drill-by-supplier': {
    name: 'Drill by Supplier', href: `${baseUrl.dashboards}/drill-by-supplier`, slug: 'drill-by-supplier', isFiltered: true, text: 'Please select a supplier to view data'
  }
}

export const secondaryLinks = {
  administrationLinks,
  dashboardLinks
}
