import React, { memo } from "react";

const SandboxToolTip = memo((props) => {
  return (
    <span className={`group relative ${props.className} cursor-default`}>
      <div
        className={`${props.details} secondary-font pointer-events-none absolute -translate-x-1/2 rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:content-[""] group-hover:opacity-100 z-50  min-w-[150px] flex justify-center`}>
        {props.text}
      </div>
      {props.children}
    </span>
  );
});

export default SandboxToolTip;
