import { useEffect, useRef, useState, useCallback } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Listbox } from '@headlessui/react';
import { classNames } from '../../utils/utils';
import ArrowSvg from '../../../assets/svgs/UploadCSV/ArrowSvg';

export default function Dropdown({
  options,
  isDisabled,
  handleClick,
  isDropdownActive,
  closeDropdown,
  selectOption,
  selectedOption,
  additionalStyle,
  width = 'w-[232px]',
}) {
  const dropdownRef = useRef(null);
  const [selected, setSelected] = useState();
  useOnClickOutside(dropdownRef, () => closeDropdown());

  useEffect(() => {
    setSelected(selectedOption || options[0]);
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSelected = useCallback(() => {
    if (typeof(selected) === 'object') return selected?.title;
    if (typeof selected === 'number')
      return options.find((o) => o.id === selected).title;
    return selected;
  }, [selected, options]);

  return (
    <>
      <div className={`${width || ''} ${additionalStyle}`} ref={dropdownRef}>
        <Listbox
          onChange={(select) => {
            setSelected(typeof select === 'object' ? select?.title : select);
            selectOption(typeof select === 'object' ? select?.id : select);
          }}
          value={selected}
        >
          <div className="w-full text-center">
            <Listbox.Button>
              <div
                className={classNames(
                  isDisabled ? 'opacity-50 cursor-default' : 'cursor-pointer',
                  `${
                    width || ''
                  } flex flex-row items-center gap-[8px] text-[17px] leading-[20px] main-font-bolded justify-center`
                )}
                onClick={handleClick}
              >
                <span>{getSelected(selected)}</span>
                <span
                  className={`${classNames(
                    isDropdownActive ? 'rotate-180' : '',
                    'flex items-center'
                  )}`}
                >
                  <ArrowSvg />
                </span>
              </div>
            </Listbox.Button>
            {isDropdownActive && (
              <Listbox.Options
                static
                className={`absolute ${
                  width || ''
                } text-left mt-[15px] max-h-60 rounded-[5px] list-scrollbar  bg-White px-[8px] shadow-[0_5px_18px_rgba(0,0,0,0.1)] border-Grey border-[1.65px] `}
              >
                {!!options?.length ? (
                  options?.map((option) => (
                    <Listbox.Option
                      key={option?.id || option}
                      className="relative cursor-pointer mt-[8px] last:mb-[8px] rounded-[8px] text-[15px] py-[8px] pl-[8px] text-Black leading-[20px] font-normal tracking-wide hover:tracking-normal hover:main-font-bolded hover:bg-[rgba(127,201,160,0.4)] not-selectable"
                      onClick={handleClick}
                      value={option}
                    >
                      {option?.title || option}
                    </Listbox.Option>
                  ))
                ) : (
                  <Listbox.Option
                    key="no-options"
                    className="relative mt-[8px] last:mb-[8px] rounded-[8px] text-[15px] py-[8px] pl-[8px] text-Black leading-[20px] font-normal tracking-wide not-selectable"
                    disabled
                    value=""
                  >
                    No options are available
                  </Listbox.Option>
                )}
              </Listbox.Options>
            )}
          </div>
        </Listbox>
      </div>
    </>
  );
}
