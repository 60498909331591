import { useState, useCallback } from 'react';
import SandboxToolTip from '../../../templates/SandboxToolTip';
import CopyToClipboardSvg from '../../../../../assets/svgs/Sandbox/CopyToClipboardSvg';
import TickBoxSvg from '../../../../../assets/svgs/Sandbox/TickBoxSvg';
import CheckSvg from '../../../../../assets/svgs/Sandbox/CheckSvg';

export default function ProductRow({
  product,
  index,
  stateButtons,
  pageDetails,
  selectProduct,
  rowRef,
}) {
  const [isCopied, setIsCopied] = useState(false);

  const renderEmpty = useCallback(() => {
    if (stateButtons.product) return <div></div>
    return null
  }, [stateButtons])

  return (
    <div
      className="relative w-full"
      ref={index === pageDetails.products.length - 1 ? rowRef : null}
    >
      <div>
        <div className="min-h-[80px] grid grid-cols-10 items-center mb-[24px] text-[15px] bg-white text-Grey text-left border rounded-xl shadow-sm leading-[20px]">
          <div className="col-span-2">
            <SandboxToolTip
              text={product.product}
              details={'left-[150px] top-[-30px] w-full min-w-[300px] max-w-[300px]'}
            >
              <div className="pl-[24px] leading-5">{product.product}</div>
            </SandboxToolTip>
          </div>
          {(stateButtons.product || stateButtons.savedProduct) && (
            <div className="col-span-2">
              <SandboxToolTip
                text={product?.sku}
                details={'left-[80px] top-[-30px] w-full max-w-[300px]'}
              >
                <div>{product?.sku}</div>
              </SandboxToolTip>
            </div>
          )}
          {(stateButtons.product || stateButtons.savedProduct) && (
            <div>{product?.supplier || product?.manufacturer || '-'}</div>
          )}
          <div>{product?.category || '-'}</div>
          <div className={`${!stateButtons.product && 'col-span-3'}`}>
            {product.co2perkg && parseFloat(product.co2perkg).toFixed(2)}
          </div>
          {stateButtons.product && (
            <div className="">
              <div className="ml-[20px]">
                <CheckSvg selected={product.verified} />
              </div>
            </div>
          )}
          {/* that is intentional */}
          {stateButtons.ingredient && (
            <>
              <div></div>
              <div></div>
            </>
          )}
          {(stateButtons.product && product?.recipe) ? (
            <SandboxToolTip
              text={product.recipe}
              details={'left-[110px] bottom-[10px] w-full max-w-[400px]'}
            >
                <div
                  className="flex cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(product.recipe);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 500);
                  }}
                >
                  {isCopied ? (
                    <CheckSvg selected={true} w={24} h={24} className="p-1" />
                  ) : (
                    <CopyToClipboardSvg />
                  )}
                </div>
            </SandboxToolTip>
          ) : renderEmpty()}
          <div className="flex justify-end">
            <div
              className="flex flex-row justify-end cursor-pointer mr-[30px] w-[40px]"
              onClick={() =>
                selectProduct(index, !pageDetails.products[index].selected)
              }
            >
              <TickBoxSvg
                selected={pageDetails.products[index].selected ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
