import { useContext } from 'react';
import Spinner from './components/templates/Spinner';
import AppRoutes from './routes/AppRoutes';
import { UserContext } from './context/UserContext';
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS);

export default function App() {
  const { isLoading } = useContext(UserContext)

  return (
    <>
      {isLoading ? (
        <div className='w-screen h-screen'>
        <Spinner />
        </div>
      ) : (
        <div className='w-full h-full overflow-hidden'>
          <AppRoutes />
        </div>
      )}
    </>
  );
}
