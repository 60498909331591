export default async function fetchEmissions(details) {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/search-products/${details.text}&${details.fromDate}&${details.toDate}${details.companyId ? '&'+details.companyId : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      return res.json();
    } catch (err) {
      console.error(err);
    }
  }