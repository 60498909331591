import useSidebar from "../../hooks/useSidebar"
import { classNames } from "../../utils/utils";

export default function BackDrop({children}) {
  const {isSidebarMinimized} = useSidebar();
  return (
    <div
      className={classNames(` h-full fixed top-0 right-0 bg-Black opacity-50 z-1 duration-500 ease-in-out`, isSidebarMinimized ? "w-[calc(100%_-_64px)]" : "w-[calc(100%_-_224px)]")}
    >
      {children}
    </div>
  )
}