import NoSidebar from "../parts/Sidebar/NoSidebar";
import ReactGA from "react-ga4";

export default function PageBoilerplate({children, name, noStyle = false, left=false, element=null}) {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: name});

  return (
    <div className='w-full h-full relative'>
      <div className={`flex justify-center items-center pb-[2px] w-full px-[64px] ${noStyle ? 'bg-White' : 'bg-Stone/30'}`}>
        {noStyle ? <NoSidebar/> : null}
      <h1
        className={`w-full flex  items-center text-center text-[34px] leading-[44px] main-font-bolded text-Black min-h-[145px] tracking-wide relative ${left ? 'justify-start flex-1' : 'justify-center'}`}>
        {name}
      </h1>
      {element}
      <title>{name}</title>
      </div>
      {!noStyle ? <div className='left-0 w-full h-0 border border-DarkStone'></div> : null}
      {children}
    </div>
  );
}