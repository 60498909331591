import React, { useContext } from "react";
import SearchProducts from "../parts/SandBox/SearchProducts";
import SandboxBoilerPlate from "../templates/SandboxBoilerPlate";
import { SandboxContext } from "../../context/SandboxContext";

export default function SandboxSearchProducts() {
  const { sandbox } = useContext(SandboxContext);

  return (
    <SandboxBoilerPlate title={`${sandbox.id ? 'Existing products' : 'Search for a product'}`} showCreateButton={false} className="h-full">
        <SearchProducts showButtons={true}/>
    </SandboxBoilerPlate>
  );
}
