import MFAForm from "../parts/Auth/MFAForm";
import PageBoilerplate from '../templates/PageBoilerplate';

export default function MFA() {
	return (
		<PageBoilerplate name="Log in to your account" noStyle={ true }>
			<MFAForm/>
		</PageBoilerplate>
	);
}
