export default async function fetchDashboard(dashboardSlug, drillId) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/dashboard`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({dashboardSlug, drillId}),
    });
    return res.json()
  } catch (err) {
    console.error(err)
  }
}