import React from "react";

import SandboxBoilerPlate from "../templates/SandboxBoilerPlate";
import SavedSandboxes from "../parts/SandBox/SavedSandboxes";

export default function SavedSandboxesPage() {
  return (
    <SandboxBoilerPlate title="Saved Sandboxes" showCreateButton={false}>
      <SavedSandboxes />
    </SandboxBoilerPlate>
  );
}
