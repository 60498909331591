import { Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import * as Yup from 'yup';
import FormInput from './FormInput';
import { requestNewCompany } from '../../../../utils/requestNewCompany';

const requestCompanyInitialValues = {
  companyName: '',
  estimatedLines: '',
};

const requestCompanyValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
  estimatedLines: Yup.number().required(
    'Estimated SKU’s per month are required'
  ),
});

export default function RequestCompanyModal({ isModalShown, closeModal }) {

  const [showError, setShowError] = useState(false);

  function check(errors) {
    return Object.values(errors).filter(v => v !== '').length;
  }

  async function requestNewCompanyReq(values, helpers) {
    try {
      const res = await requestNewCompany(values.companyName, Number(values.estimatedLines));
      if (res.ok) {
        setShowError(false);
        helpers.resetForm();
        closeModal();
      }
    } catch(err) {
      setShowError(true);
    }
  }

  return (
    <Formik
      initialValues={requestCompanyInitialValues}
      validationSchema={requestCompanyValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={requestNewCompanyReq}
    >
      {({ values, errors, handleBlur, handleChange, handleSubmit }) => (
        <Transition.Root show={isModalShown} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 top-2/4 max-w-[440px]"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full md:items-end justify-center p-4 text-center items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 scale-95 md:scale-0"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-[440px] pt-[32px] my-auto">
                    <div>
                      <div className="text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl text-center font-bold text-black main-font-bolded"
                        >
                          Request New Company
                        </Dialog.Title>
                        <Form className="mt-[24px]">
                          <div className="mb-[36px]">
                            <div className="mb-[16px]">
                              <FormInput
                                name="companyName"
                                label="New Company Name"
                                value={values.companyName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                showError={errors.companyName}
                              />
                            </div>
                            <div className="mb-[16px]">
                              <FormInput
                                name="estimatedLines"
                                label="Estimated SKU’s per month"
                                type="number"
                                value={values.estimatedLines}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                showError={errors.estimatedLines}
                              />
                            </div>
                          </div>
                          <div className="flex justify-center mt-[16px] mb-[28px] flex-col items-center gap-4">
                            {showError && <p className='text-red-500'>Something went wrong.</p>}
                            <button
                              type="submit"
                              className={`${
                                 check(errors) > 0 &&
                                "opacity-60 text-Grey cursor-default"
                              } green-button`}
                              onClick={handleSubmit}
                            >
                              Request
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </Formik>
  );
}
