import LoginForm from '../parts/Auth/LoginForm';
import PageBoilerplate from '../templates/PageBoilerplate';

export default function Login() {
  return (
    <PageBoilerplate name="Log in to your account" noStyle={true}>
      <LoginForm />
    </PageBoilerplate>
  );
}
