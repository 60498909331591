import { useCallback, useEffect, useState, useContext } from 'react';
import Dropdown from '../../templates/Dropdown';
import fetchCompanies from '../../../utils/fetchCompanies';
import { UserContext } from '../../../context/UserContext';

export default function CompanyDropdown({ onSelect }) {
  const { companyId, saveCompany } = useContext(UserContext);
  const [selectedOption, setSelectedOption] = useState('Select a company');
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchCompanies().then((res) => {
      if (res) {
        const list = res?.map((opt) => ({
          id: opt?.id,
          title: opt?.name,
        }));
        setOptions(list);
        if (list.length === 1) {
          saveCompany(list[0].id);
          onSelect();
        }
      }
    });
  }, [saveCompany, onSelect]);

  useEffect(() => {
    if (companyId !== -1 && !isNaN(companyId)) {
      if (options?.length > 1) {
        setSelectedOption(options.find((el) => el.id === companyId)?.title);
        onSelect();
      }
    }
  }, [companyId, options, onSelect]);

  useEffect(() => {
    if (selectedOption !== 'Select a company') {
      if (typeof selectedOption === 'number') {
        saveCompany(selectedOption);
        onSelect();
      }
    }
  }, [selectedOption, onSelect, saveCompany]);

  const toggleDropdown = useCallback(() => {
    setIsDropdownActive((prev) => !prev);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsDropdownActive(false);
  }, []);

  return (
    <>
      {options.length > 1 && (
        <div className="flex flex-col gap-[8px] max-w-[272px] rounded">
          <p className="secondary-font w-[568px] font-normal leading-[22px] text-Grey">
            Select a company
          </p>
          <Dropdown
            selectOption={setSelectedOption}
            options={options}
            handleClick={toggleDropdown}
            isDisabled={false}
            isDropdownActive={isDropdownActive}
            closeDropdown={closeDropdown}
            selectedOption={selectedOption}
            additionalStyle="border border-[1.5px] border-Grey rounded-md py-[7px] bg-white z-2"
            width="w-[272px]"
          />
        </div>
      )}
    </>
  );
}
