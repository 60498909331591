// should be aight
export default async function fetchNewSandboxProduct(data) {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sandbox-products/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(data),
          });

        return res.json();
    } catch (error) {
        console.error(error);
    }
}