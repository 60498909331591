import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { sidebarLinks } from '../constants/sidebarLinks';
import { UserContext } from '../context/UserContext';
import CustomerSpaceRoutes from './CustomerSpaceRoutes';
import Login from '../components/pages/Login';
import MFA from '../components/pages/MFA';

export default function AppRoutes() {
  const { isLoggedIn, role, isVerifyingCode } = useContext(UserContext);

  function authHandler(next, fallback, secondCondition = true) {
    if (isLoggedIn) return next;
    if (secondCondition)
      return fallback;
    else return next;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={authHandler(
          <Navigate to={role === 'consultancy' ? sidebarLinks.dashboards.href : sidebarLinks.administration.href} replace />,
          <Login />
        )}
      />
        <Route
          path="/verify-code"
          element={authHandler(<Navigate
            to="/"
            replace/>, <MFA/>, isVerifyingCode)}
        />
      <Route
        path="/*"
        element={authHandler(
          <CustomerSpaceRoutes />,
          <Navigate to="/" replace />
        )}
      />
    </Routes>
  );
}
