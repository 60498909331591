import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import fetchSuppliers from '../../../utils/fetchSuppliers';
import fetchCategories from '../../../utils/fetchCategories';
import fetchDashboard from '../../../utils/fetchDashboard';
import { secondaryLinks } from '../../../constants/secondaryLinks';
import Dropdown from '../../templates/Dropdown';
import { returnStringIfItExists } from '../../../utils/utils';
import Lottie from 'lottie-react';
import dashboardSpinner from '../../../../assets/animations/dashboardSpinner.json';
import BackDrop from '../../templates/BackDrop';
import CurlyArrowSvg from '../../../../assets/svgs/UploadCSV/CurlyArrowSvg';
import CompanyDropdown from './CompanyDropdown';
import { UserContext } from '../../../context/UserContext';
import fetchConsultancyDash from '../../../utils/fetchConsultancyDash';

export default function FilteredDashboard({ slug, name, text }) {
  const { companyId, role } = useContext(UserContext);
  const iFrame = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [selectedOption, setSelectedOption] = useState(name);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [options, setOptions] = useState([]);
  const [isBackDropVisible, setIsBackDropVisible] = useState(true);

  useEffect(() => {
    !isHidden && setIsHidden(true);
    if (slug === secondaryLinks.dashboardLinks['drill-by-category'].slug) {
      fetchCategories().then((res) => {
        const list = res?.data?.map((opt) => ({
          id: opt?.id,
          title: opt?.attributes?.title,
        }));
        setOptions(list);
      });
    }
    if (slug === secondaryLinks.dashboardLinks['drill-by-supplier'].slug) {
      if (role !== 'consultancy') {
        fetchSuppliers().then((res) => {
          const list = res?.map((opt) => ({
            id: opt?.id,
            title: opt?.title,
          }));
          setOptions(list);
        });
      } else if (companyId !== -1) {
        fetchSuppliers(companyId.toString()).then((res) => {
          const list = res?.map((opt) => ({
            id: opt?.id,
            title: opt?.title,
          }));
          setOptions(list);
        });
      }
    }
    getDashboard();
  }, [slug, !options, selectedOption, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDashboard = useCallback(() => {
    if (selectedOption !== name) {
      setIsHidden(false);
      setIsLoading(true);
      if (role === 'consultancy') {
        fetchConsultancyDash(companyId, slug, selectedOption).then(
          ({ iframeUrl }) => {
            iFrame.current.src = iframeUrl;
            iFrame.current.onload = () => {
              setIsLoading(false);
            };
          }
        );
      } else {
        fetchDashboard(slug, selectedOption).then(({ iframeUrl }) => {
          iFrame.current.src = iframeUrl;
          iFrame.current.onload = () => {
            setIsLoading(false);
          };
        });
      }
    }
  }, [name, selectedOption, slug, companyId, role]);

  const toggleDropdown = useCallback(() => {
    setIsDropdownActive((prev) => !prev);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsDropdownActive(false);
  }, []);

  const removeBackDrop = useCallback(() => {
    setIsBackDropVisible(false);
  }, []);

  return (
    <>
      <div className="flex flex-col w-full h-full gap-[8px] pt-[24px]">
        <div className="flex pl-[45px] pr-[4.25rem]">
          <div className="flex flex-col gap-[8px] flex-1">
            <p className="secondary-font w-[568px] font-normal leading-[22px] text-Grey">
              {returnStringIfItExists(text)}
            </p>
            <Dropdown
              selectOption={setSelectedOption}
              options={options}
              handleClick={toggleDropdown}
              isDisabled={false}
              isDropdownActive={isDropdownActive}
              closeDropdown={closeDropdown}
              selectedOption={selectedOption}
              additionalStyle="border border-[1.5px] border-Grey rounded-md py-[7px]"
              width="w-[272px]"
            />
          </div>
          {role === 'consultancy' && options && (
            <CompanyDropdown onSelect={removeBackDrop} />
          )}
        </div>
        {isLoading && (
          <Lottie
            animationData={dashboardSpinner}
            loop={true}
            className="h-[calc(100%_-_185px)] bg-White"
          />
        )}
        {isHidden && (
          <div className="w-full h-full">
            <img
              className="w-[400px] h-[400px] absolute bottom-0 right-0"
              src="/imgs/Zedible_Branding_Offset.png"
              alt=""
            />
          </div>
        )}
        {isBackDropVisible && companyId === -1 && role === 'consultancy' && (
          <BackDrop>
            <div className="absolute right-[18.5rem] top-60 rotate-90">
              <CurlyArrowSvg />
            </div>
          </BackDrop>
        )}
        <div className="w-full h-full">
          <iframe
            title="metabase-dashboard"
            ref={iFrame}
            className={
              isLoading
                ? ''
                : 'w-full h-[calc(100%_-_140px)] overflow-y-scroll z-2 pl-[45px] pr-[47px]'
            }
          />
        </div>
      </div>
    </>
  );
}
