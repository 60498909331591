import ErrorMsg from "../../templates/ErrorMsg";
import useUploadCSVForm from "../../../hooks/useUploadCSVForm";
import {returnErrorMessage, returnErrorMessageWithRows} from "../../../utils/utils";

export default function ErrorMessages({errors}) {
  const {
    wrongExtensionFiles,
    largeFiles,
    duplicateFiles,
    brokenHeadersFiles,
    emptyFiles,
    wrongTypeFiles,
    exceededRowsFiles,
    badDateFiles,
    missingDataFiles,
    excededFilesLimit,
    dataType
  } = useUploadCSVForm();

  return ((wrongExtensionFiles || largeFiles || duplicateFiles || brokenHeadersFiles || emptyFiles || wrongTypeFiles || exceededRowsFiles || excededFilesLimit || errors?.formFiles) &&
    <div className='fixed inset-1/2 flex flex-col justify-center items-center z-3'>
      {returnErrorMessage(wrongExtensionFiles, 'File format not accepted. Upload CSV or Excel data only. Wrong file:', 'Files format not accepted. Upload CSV or Excel data only. Wrong files:')}
      {returnErrorMessage(largeFiles, 'The following file is larger than 5MB:', 'The following files are larger than 5MB:')}
      {returnErrorMessage(duplicateFiles, 'The following file is a duplicate:', 'The following files are duplicates:')}
      {returnErrorMessage(brokenHeadersFiles, 'File format incorrect, please check template format and try again:', 'Files format incorrect, please check template format and try again:', false)}
      {returnErrorMessage(emptyFiles, 'The following file is empty:', 'The following files are empty:', false)}
      {returnErrorMessage(wrongTypeFiles, `The following file isn't ${dataType.toLowerCase()}:`, `The following files aren't ${dataType.toLowerCase()}:`, false)}
      {returnErrorMessage(exceededRowsFiles, 'The following file exceeds the row limit (10000 rows):', 'The following files exceed the row limit (10000 rows):', false)}
      {returnErrorMessageWithRows(badDateFiles, 'The following file has dates formatted incorrectly:', 'The following files have dates formatted incorrectly:')}
      {returnErrorMessageWithRows(missingDataFiles, 'The following file has missing data:', 'The following files have missing data:')}
      {excededFilesLimit ? <ErrorMsg>The total size of the files you are trying to upload should be less than 5MB. Please select fewer or smaller files and try again.</ErrorMsg> : null}
      {errors?.formFiles && <ErrorMsg>{errors?.formFiles}</ErrorMsg>}
    </div>
  )
}