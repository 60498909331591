/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SandboxPhoto from '../../../../assets/photos/SandboxPhoto.png';
import { SandboxContext } from '../../../context/SandboxContext';
import ConfirmationModal from './Modals/ConfirmationModal';
import { analyticsClickedButton } from '../../../utils/utils';
import CompanyDropdown from '../Dashboards/CompanyDropdown';
import BackDrop from '../../templates/BackDrop';
import { UserContext } from '../../../context/UserContext';
import CurlyArrowSvg from '../../../../assets/svgs/UploadCSV/CurlyArrowSvg';

export default function InfoPage() {
  const { companyId, role } = useContext(UserContext);
  const { resetContext } = useContext(SandboxContext);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [isBackDropVisible, setIsBackDropVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    resetContext();
  }, []);

  const removeBackDrop = useCallback(() => {
    setIsBackDropVisible(false);
  }, []);

  return (
    <>
      {isBackDropVisible && companyId === -1 && role === 'consultancy' && (
        <BackDrop>
          <div className="absolute right-[21rem] top-[16.5rem] rotate-90">
            <CurlyArrowSvg />
          </div>
        </BackDrop>
      )}
      <div className="w-full flex flex-col h-under-header max-h-800:overflow-y-auto overflow-x-hidden bg-Stone/30">
        <div className="ml-[64px] pt-[48px] h-full mb-[250px]">
          <div className="flex justify-between items-center mr-[64px]">
            <div className="max-w-[800px] text-[16px] secondary-font text-Grey">
              Welcome to the sandbox! Here, you can experiment with product
              substitutions to see how they can help you improve CO2 emissions
              while also assessing their impact on your cost of sale spending.
            </div>
            {role === 'consultancy' && <CompanyDropdown onSelect={removeBackDrop} />}
          </div>
          <div className="mt-[32px] flex justify-start">
            <div className="flex flex-col mr-[76px]">
              <div
                className="max-w-[560px] bg-white rounded-xl mb-[16px] xl:w-[560px]"
                style={{ boxShadow: '0px 5px 18px rgba(0, 0, 0, 0.1)' }}
              >
                <div className="ml-[32px] mt-[32px]">
                  <div className="text-2xl main-font-bolded leading-7">
                    Ingredient switch out
                  </div>
                  <div className="max-w-[370px] text-Grey text-base mt-[14px] secondary-font mb-[30px]">
                    If you want to find similar raw ingredients that can be
                    substituted, you can use the 'Ingredient switch out'
                    selection.
                  </div>
                </div>
              </div>
              <div
                className="max-w-[560px] bg-white rounded-xl mb-[16px] xl:w-[560px]"
                style={{ boxShadow: '0px 5px 18px rgba(0, 0, 0, 0.1)' }}
              >
                <div className="ml-[32px] mt-[32px]">
                  <div className="text-2xl main-font-bolded leading-7">
                    Product switch out
                  </div>
                  <div className="max-w-[370px] text-Grey text-base mt-[14px] secondary-font mb-[30px]">
                    If you already know the brand or product name, you can
                    search our product database for specific products that can
                    be switched.
                  </div>
                </div>
              </div>
              <div
                className="max-w-[560px] bg-white rounded-xl mb-[16px] xl:w-[560px]"
                style={{ boxShadow: '0px 5px 18px rgba(0, 0, 0, 0.1)' }}
              >
                <div className="ml-[32px] mt-[32px]">
                  <div className="text-2xl main-font-bolded leading-7">
                    Create New
                  </div>
                  <div className="max-w-[500px] text-Grey text-base mt-[14px] secondary-font mb-[30px]">
                    If the product you want to use is not in our database, but
                    you have all the necessary details, you can create a new
                    product. If you are unable to find the CO2 per kilogram for
                    this item, simply click on the information icon located next
                    to the field. Our Zedible Support team will be available to
                    assist you.
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[452px] h-[453px]">
              <img src={SandboxPhoto} alt="spinner_photo" />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full bg-[#FBFBF8]">
          <div className="left-0 w-full h-0 border border-DarkStone"></div>
          <div>
            <div className="flex flex-row justify-center my-[36px]">
              <div
                className="grey-button mr-[16px] bg-DarkStone cursor-pointer"
                onClick={() => {
                  analyticsClickedButton(
                    'Sandbox Info Page',
                    'Saved Sandboxes'
                  );
                  navigate('/sandbox/saved-sandboxes');
                }}
              >
                Saved Sandboxes
              </div>
              <div
                className="yellow-button"
                onClick={() => {
                  analyticsClickedButton('Sandbox Info Page', 'Run Sandbox');
                  navigate('/sandbox/select-product');
                }}
              >
                Run Sandbox
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setConfirmationModal}
          type="refresh"
          buttonName="Refresh"
          title="Page Refresh"
        />
      </div>
    </>
  );
}
