export async function requestNewCompany(companyName, estimatedLines) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/company/request-new-company`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ companyName, estimatedLines })
    });
    return res;
  } catch (err) {
    console.error(err);
  }
}