/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form } from "formik";
import { contactModalInitialState, contactModalValidationSchema, createProductModalInitialState } from "../../../../constants/modals";
import FormInput from "./FormInput";
import { SandboxContext } from "../../../../context/SandboxContext";
import fetchContactRequest from "../../../../utils/fetchContactRequest";

export default function ContactModal({returnPage}) {
  const { showContactModal, setShowContactModal, createProductData, compareProducts, setCreateProductData } = useContext(SandboxContext);
  const [requestDetails, setRequestDetails] = useState({
    sent: false,
    message: "",
    error: false,
  });

  function checkIfValid(values, errors) {
    let isValid = true;

    if (Object.keys(errors).length !== 0) {
      return false;
    }

    for (const value of Object.entries(values)) {
      if (value[1].length === 0) {
        isValid = false;
      }
    }

    return isValid;
  }

  async function handleContactRequest(values) {
    values.productData = {
      productName: createProductData.name,
      productSku: createProductData.sku,
      productWeight: createProductData.weight,
      productPrice: createProductData.unitPrice,
      productManufacturer: createProductData.manufacturer,
      productSupplier: createProductData.supplier,
    };

    const result = await fetchContactRequest(values);
    setRequestDetails({
      ...requestDetails,
      sent: true,
      message: result.message,
    });
  }

  const handleCloseButton = () => {
    setShowContactModal(false);
    
    if (compareProducts.length > 0) {
      returnPage(true);
    } else {
      returnPage(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={contactModalInitialState}
        validationSchema={contactModalValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values) => {
          handleContactRequest(values);
        }}>
        {({ setValues, values, errors, handleBlur, handleChange }) => (
          <Transition.Root show={showContactModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10 top-2/4 max-w-[440px]"
              onClose={() => {
                setShowContactModal(false);
                setValues(contactModalInitialState);
                setCreateProductData(createProductModalInitialState);
              }}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full md:items-end justify-center p-4 text-center items-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 scale-95 md:scale-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-[440px] pt-[32px] my-auto">
                      <div>
                        <div className="text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl text-center font-bold text-black main-font-bolded">
                            Your details
                          </Dialog.Title>
                          <Form className="mt-[24px]">
                            <div className="mb-[36px]">
                              <div className="mb-[16px]">
                                <FormInput
                                  name={"name"}
                                  label={"Name"}
                                  value={values.name}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="mb-[16px]">
                                <FormInput
                                  name={"telephone"}
                                  label={"Telephone"}
                                  value={values.telephone}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <FormInput
                                  name={"email"}
                                  label={"Email"}
                                  value={values.email}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {requestDetails.sent && (
                              <div className="secondary-font text-center mt-[16px]">
                                <div>{requestDetails.message}</div>
                              </div>
                            )}
                            <div className="flex justify-center mt-[16px] mb-[28px]">
                              {!requestDetails.error && !requestDetails.sent ? (
                                <button
                                  type="submit"
                                  className={`${
                                    !checkIfValid(values, errors) &&
                                    "opacity-60 text-Grey cursor-default"
                                  } green-button`}>
                                  Request help
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className={`green-button cursor-pointer`}
                                  onClick={() => {
                                    setValues(contactModalInitialState);
                                    handleCloseButton();
                                    setCreateProductData(createProductModalInitialState);
                                  }}>
                                  Close
                                </button>
                              )}
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </Formik>
    </>
  );
}
