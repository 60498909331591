import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import useIsLoggedIn from '../../../hooks/useAuth';
import fetchLogin from '../../../utils/fetchLogin';
import { useNavigate } from 'react-router-dom';

const SignInSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export default function LoginForm() {
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const navigate = useNavigate();

  const { setToken, startSession } = useIsLoggedIn();

  function auth(values) {
    const { username, password } = values;
    fetchLogin(username, password)
      .then((data) => {
        if (data?.user?.role.toLowerCase() === 'consultancy') {
          startSession(data.user.id, data.user.phoneLastTwoDigits);
          return navigate('/verify-code');
        }
        else if (!data?.jwt) {
          return setWrongCredentials(true);
        } else setToken(data.jwt);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="flex justify-center items-center mt-[48px] ">
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={SignInSchema}
        onSubmit={auth}
      >
        {({ errors, touched }) => (
          <Form className="mt-8 space-y-6 w-[35%]">
            <div className="rounded-md -space-y-px flex flex-col gap-5">
              <div>
                <label htmlFor="username">Username: </label>
                <Field
                  id="username"
                  name="username"
                  type="username"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Username"
                />
                {errors.username && touched.username ? (
                  <p className="flex justify-center text-Error">
                    {errors.username}
                  </p>
                ) : null}
              </div>
              <div>
                <label htmlFor="password">Password: </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
                {errors.password && touched.password ? (
                  <p className="flex justify-center text-Error">
                    {errors.password}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-5">
                {wrongCredentials ? (
                  <div className="text-Error text-center">
                    Wrong username or password
                  </div>
                ) : null}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="group relative w-1/2 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-Green"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
